import React  from "react";
import StoryDetail from "@components/storyDetail/StoryDetail";
import {graphql} from 'gatsby';
import Header from "@menus/header"

const StoryDetailsTemplate = (props) => {
  const lang = props.pageContext.lang
  const story = props.data.story
  const storyLang = story[lang]
 
  return (
    <section>
      <Header location={props.location}/> 
      {    
        <StoryDetail 
          voices = {storyLang.voices}
          storySummary = {storyLang.summary}
          storySeoSummary = {storyLang.seoSummary}
          storyTitle = {storyLang.title}
          storyCover = {story.localImage.childImageSharp.gatsbyImageData}
          storyId = {story.id}
          premiumAudioRef = {story[lang].premiumAudioRef}
          freeAudioRef = {story[lang].freeAudioRef}
          sampleAudioRef = {story[lang].sampleAudioRef}
          spiciness = {story.spiciness}
          scenario = {story.scenario[lang]}
          genre = {story.genre[lang]}
          fantasies = {story.fantasies}
          mainFantasy = {story.mainFantasy[lang]}
          slug={storyLang.slug}
          seoTitle={storyLang.seoTitle}
          seoDescription={storyLang.seoDescription}
          duration={storyLang.duration}
          premiumContent ={storyLang.premiumContent}
          seriesStories={props.data.seriesStories}
          similarStories={props.data.similarStories}
          sameScenarioStories={props.data.sameScenarioStories}
          allStory={props.data.allStory}
          lang={lang}
          thumbnail={story.localImage.thumbnail}
          id={story.id}
          audiodesiresOriginal={story.audiodesiresOriginal}
          indicators={storyLang.indicators}
          readerContent={storyLang.readerContent}
          lovenseSync={storyLang.lovenseSync}
        /> 
      } 
    </section>
  );
}; 

// this way will inject query into props.data
export const query = graphql`
query StoryQuery(
  $storyId: String!, 
  $seriesStoriesIds: [String!], 
  $similarStoriesIds: [String!], 
  $sameScenarioStoriesIds: [String!] 
  $isEn: Boolean!, 
  $isEs: Boolean!, 
  $isDe: Boolean!)
  {
  story(id: {eq: $storyId}) {
    audiodesiresOriginal
    id
    spiciness
    scenario {
      en {
        scenario
        slug
      }
      es {
        scenario
        slug
      }
      de {
        scenario
        slug
      }
    }
    genre {
      id
      en  @include(if: $isEn) {
        genre
      }
      es  @include(if: $isEs) {
        genre
      }
      de  @include(if: $isDe) {
        genre
      }
    }
    ...langStoryInfo
    localImage {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 600, 
          height: 600, 
          layout: CONSTRAINED)
      }
    }
    localImage {
      thumbnail: childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED,
          width: 120, 
          layout: CONSTRAINED)
      }
    }
    mainFantasy {
      en {
        fantasy
        slug
      }
      es {
        fantasy
        slug
      }
      de {
        fantasy
        slug
      }
    }
    fantasies {
      id
      languages
      en @include(if: $isEn){
        fantasy
        slug
      }
      es @include(if: $isEs){
        fantasy
        slug
      }
      de @include(if: $isDe){
        fantasy
        slug
      }
    }
  }
  similarStories: allStory(filter: {id: {in: $similarStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisStoryData
      }
    }
  }
  seriesStories: allStory(filter: {id: {in: $seriesStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisStoryData
      }
    }
  }
  sameScenarioStories: allStory(filter: {id: {in: $sameScenarioStoriesIds}}) {
    edges {
      node {
        ...moreLikeThisStoryData
      }
    }
  }
}
`;  





export default StoryDetailsTemplate;

